import React, { useEffect, useState } from "react";
import CtaButton from "./CtaButton";
import ElectricBoxes from "../landing/ElecticBoxes";

export default function Purpose() {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {/* Diagonal Split Banner Section */}
      <section
        id="purpose"
        className="relative h-auto sm:h-28 md:h-screen lg:h-screen overflow-hidden flex items-center justify-center p-4"
      >
        {/* Background Layer 1 (Moves slower, Indigo diagonal) */}
        <div
          className="absolute inset-0 -skew-y-6 bg-gradient-to-br from-indigo-800 from-1% via-indigo-900 via-15% to-black to-90%"
          style={{
            transform: `translateY(${offsetY * 0.6}px) skewY(-6deg)`, // Moves slower
          }}
        ></div>

        {/* Background Layer 2 (Moves faster, Gray diagonal) */}
        <div
          className="absolute inset-0 bg-gradient-to-br from-gray-900 from-1% via-gray-900 via-15% to-black to-90% skew-y-3"
          style={{
            transform: `translateY(${offsetY * 0.1}px) skewY(9deg)`, // Moves faster
          }}
        ></div>

        {/* Foreground Content (Moves at normal speed) */}
        <div
          className="relative z-10 flex items-center h-full px-8"
          style={{
            transform: `translateY(${offsetY * 0.1}px)`, // Slight movement
          }}
        >
          <div className="flex flex-col lg:flex-row w-full lg:w-auto">
            {/* Left side: Text content */}
            <div className="sm:pl-10 lg:pl-40 lg:pb-20 lg:w-1/2 md:w-2/3 sm:py-20">
              <h2 className="sm:pl-8 text-4xl font-bold text-gray-100 lg:p-0">
                We Believe Security For Everyone
              </h2>
              <p className="mt-4 text-lg text-gray-300 p-5 lg:p-0">
                Many organizations, particularly those new to cloud adoption,
                lack the resources necessary to implement effective cloud
                security measures. Time constraints and competing priorities can
                make it difficult for businesses to allocate sufficient time to
                learn about and implement cloud security best practices.
              </p>
              <div className="mt-6">
                <CtaButton text="Learn More" link="#" />
              </div>
            </div>

            {/* Right side: Image (Stacks below on smaller screens) */}
            <div className="lg:w-1/2 flex justify-center lg:justify-start sm:mr-40 sm:mb-16">
              <ElectricBoxes />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
